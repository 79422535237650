import { bookingSystemApi } from "src/api/booking-system";
import { slice } from "src/slices/booking-system";
import * as XLSX from "xlsx";
const moment = require("moment");

const getAllBookings = (userId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAllBookingsLoading(true));

    const response = await bookingSystemApi.getAllBookings(userId);

    dispatch(slice.actions.setAllBookings(response));
    dispatch(slice.actions.setAllBookingsLoading(false));
  } catch (err) {
    console.log(err);

    dispatch(slice.actions.setAllBookings([]));
    dispatch(slice.actions.setAllBookingsLoading(false));
  }
};

const getClientAndBuyingGroupData =
  (userId, searchField, signal) => async (dispatch) => {
    try {
      dispatch(slice.actions.setNewClientDropdownValuesLoading(true));
      const response = await bookingSystemApi.getClientAndBuyingGroupData(
        userId,
        searchField,
        signal
      );

      dispatch(slice.actions.setNewClientDropdownValues(response));
      dispatch(slice.actions.setNewClientDropdownValuesLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

const getSalesCampaignData = (userId, salesCampaign) => async (dispatch) => {
  try {
    const response = await bookingSystemApi.getSalesCampaignData(
      userId,
      salesCampaign
    );

    dispatch(slice.actions.setSalesCampaignDropdownValues(response));
  } catch (err) {
    console.log(err);
  }
};

const getAllLocations = (userId) => async (dispatch) => {
  try {
    const response = await bookingSystemApi.getAllLocations(userId);

    dispatch(slice.actions.setAllLocations(response));
  } catch (err) {
    console.log(err);
  }
};

const updateBooking = (userId, fields, appointmentId) => async () => {
  try {
    await bookingSystemApi.updateBooking(userId, fields, appointmentId);
  } catch (err) {
    console.log(err);
  }
};

const deleteBooking = (userId, appointmentId) => async (dispatch) => {
  try {
    await bookingSystemApi.deleteBooking(userId, appointmentId);

    dispatch(thunks.getAllBookings(userId));
  } catch (err) {
    console.log(err);
  }
};

const getBookingLinks = (userId) => async (dispatch) => {
  try {
    const response = await bookingSystemApi.getBookingLinks(userId);

    dispatch(slice.actions.setBrandPortfolioLink(response.brandPortfolioLink));
    dispatch(slice.actions.setShowroomFlyersLink(response.showroomFlyersLink));
  } catch (err) {
    console.log(err);
  }
};

const setBookingLinks =
  (userId, brandPortfolioLinkValue, showroomFlyersLinkValue) => async () => {
    try {
      await bookingSystemApi.setBookingLinks(
        userId,
        brandPortfolioLinkValue,
        showroomFlyersLinkValue
      );

      window.location.reload();
    } catch (err) {
      console.log(err);
      window.location.reload();
    }
  };

const exportBookingsToExcel = (userId, filters) => async (dispatch) => {
  const { allBookingsRows, ...restOfFilters } = filters;

  dispatch(slice.actions.setExportLoading(true));

  const result = await bookingSystemApi.exportBookings(userId, restOfFilters);

  //
  // GENERATE EXCEL FILE
  //

  // Create a worksheet from the data
  const ws = XLSX.utils.json_to_sheet(result);

  // Create a workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert the workbook to an array buffer
  const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert the array buffer to a blob
  const blob = new Blob([excelFile], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Bookings export ${moment().format("DD/MM/YYYY HH:mm:ss")}.xlsx`
  );

  // Trigger a click event to start the download
  link.click();

  // Clean up by revoking the URL object
  URL.revokeObjectURL(url);

  dispatch(slice.actions.setExportLoading(false));
};

const getSkippedBookings = (userId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setSkippedBookingsLoading(true));

    const response = await bookingSystemApi.getSkippedBookings(userId);

    dispatch(slice.actions.setSkippedBookings(response));
    dispatch(slice.actions.setSkippedBookingsLoading(false));
  } catch (err) {
    console.log(err);

    dispatch(slice.actions.setSkippedBookings([]));
    dispatch(slice.actions.setSkippedBookingsLoading(false));
  }
};

const exportSkippedBookingsToExcel = (userId, filters) => async (dispatch) => {
  const { skippedBookingsRows, ...restOfFilters } = filters;

  dispatch(slice.actions.setExportLoading(true));

  const result = await bookingSystemApi.exportSkippedBookings(
    userId,
    restOfFilters
  );

  //
  // GENERATE EXCEL FILE
  //

  // Create a worksheet from the data
  const ws = XLSX.utils.json_to_sheet(result);

  // Create a workbook with the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Convert the workbook to an array buffer
  const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert the array buffer to a blob
  const blob = new Blob([excelFile], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Bookings export ${moment().format("DD/MM/YYYY HH:mm:ss")}.xlsx`
  );

  // Trigger a click event to start the download
  link.click();

  // Clean up by revoking the URL object
  URL.revokeObjectURL(url);

  dispatch(slice.actions.setExportLoading(false));
};

export const thunks = {
  getAllBookings,
  getClientAndBuyingGroupData,
  getSalesCampaignData,
  getAllLocations,
  updateBooking,
  deleteBooking,
  getBookingLinks,
  setBookingLinks,
  exportBookingsToExcel,
  getSkippedBookings,
  exportSkippedBookingsToExcel,
};
