import { useEffect, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
// Hooks
import { useTranslation } from "react-i18next";
import { useRouter } from "src/hooks/use-router";
// Icons
import {
  CustomButton,
  CustomDivider,
  CustomTextField,
  CustomTooltip,
} from "src/components/inputs";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Chip,
  DialogActions,
  Popper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import {
  Delete,
  SaveOutlined,
  Replay as RevertIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/booking-system";
import { thunks as bookingProcessThunks } from "src/thunks/booking-process";
import { slice } from "src/slices/booking-system";

export const getStatusType = {
  draft: "Draft",
  pending: "Pending",
  approved: "Approved",
  approvingInProcess: "Approval in process",
  rejected: "Rejected",
};

export const dataGridStyle = {
  fontFamily: "tmwCustomFont",
  "& .MuiDataGrid-columnHeaders": {
    borderTop: "1px solid #E6E6E6",
    borderRadius: 0,
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiInputBase-input": {
    fontSize: 12,
    fontWeight: 500,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
  },
  "& .MuiDataGrid-columnHeader--emptyGroup": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:not(:first-of-type)": {
    borderLeft: "1px solid #E6E6E6",
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    borderLeft: "1px solid #E6E6E6",
    letterSpacing: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-row:not(:last-child)": {
    paddingTop: "1px",
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnSeparator": {
    opacity: "0 !important",
  },
  "& .first-header-style": {
    borderLeft: "1px solid #E6E6E6 !important",
    outline: "none !important",
  },
  "& .last-header-style": {
    borderRight: "1px solid #E6E6E6 !important",
  },
  "& .header-style": {
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  "& .actions": {
    borderLeft: "1px solid #E6E6E6",
    display: "flex",
    justifyContent: "space-around",
  },
  "& .MuiDataGrid-footerContainer": {
    letterSpacing: 2,
    textTransform: "uppercase",
    fontSize: 11,
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiTablePagination-select": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiDataGrid-virtualScroller": {
    borderBottom: "1px solid #E6E6E6",
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    minHeight: "50px",
  },
  "& .column-groups": {
    marginTop: "0.5px",
  },
  "& .archived": {
    backgroundColor: "#FFEDED",
    "&:hover": {
      backgroundColor: "#F8EBEB",
    },
  },
};

const applyArchivedStyle = (archived) => {
  return archived ? "archived" : "";
};

// Status Cell custom Component
export const statusCellComponent = (status, inactive) => {
  return typeof status === "string" ? (
    <Box
      style={{ position: "relative", margin: "0 auto", marginRight: "-5px" }}
    >
      <Chip
        style={{
          height: "18px",
          width: "18px",
          borderRadius: "50%",
          marginRight: "10px",
          marginLeft: "5px",
          background: inactive
            ? "black"
            : status === getStatusType.draft
            ? "#F5911E"
            : status === getStatusType.pending
            ? "#FF0000"
            : status === getStatusType.approved
            ? "#39B349"
            : status === getStatusType.approvingInProcess
            ? "#54C3EA"
            : status === getStatusType.rejected
            ? "black"
            : "",
        }}
      />
    </Box>
  ) : (
    <Chip
      style={{
        height: "18px",
        width: "18px",
        borderRadius: "50%",
        marginRight: "10px",
        marginLeft: "5px",
      }}
      color={"default"}
    />
  );
};

export const columnsDefinition = (
  userId,
  groupTypes,
  userGroup,
  modalOpened,
  setModalOpened,
  allBookings,
  allLocations,
  timeSlots,
  setDeleteAppointmentModalId,
  filters,
  setFilters
) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const [changedValues, setChangedValues] = useState([]);
  const [notClickedRow, setNotClickedRow] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState("");

  const handleSaveRowChanges = (row) => {
    // Send request to the backend with the values and row id
    dispatch(
      thunks.updateBooking(
        userId,
        changedValues.filter((value) => value.id === row.id),
        row.appointmentId
      )
    );

    // Remove changed values (row) from the temp array
    setChangedValues(changedValues.filter((value) => value.id !== row.id));
  };

  const handleRevertRowChanges = (id, row) => {
    const updatedBookings = allBookings.map((booking) => {
      const foundChangedValues = changedValues.filter(
        (changedValue) => changedValue.id === booking.id
      );

      if (foundChangedValues.length) {
        const objectToReturn = { ...booking };

        foundChangedValues.map((foundChangedValue) => {
          if (foundChangedValue && foundChangedValue.id !== id) {
            if (foundChangedValue.field === "location") {
              objectToReturn.location = foundChangedValue.value;
            }

            if (foundChangedValue.field === "date") {
              objectToReturn.date = foundChangedValue.value;
            }

            if (foundChangedValue.field === "time") {
              objectToReturn.time = foundChangedValue.value;
            }

            if (foundChangedValue.field === "meetingType") {
              objectToReturn.meetingType = foundChangedValue.value;
            }
          }
        });

        return objectToReturn;
      }

      return booking;
    });

    // Persist filters
    const filteredUpdatedBookings = updatedBookings.filter((booking) => {
      return (
        (!filters.selectedBrand ||
          booking.brands?.includes(filters.selectedBrand)) &&
        (!filters.selectedCategory ||
          booking.categories?.includes(filters.selectedCategory)) &&
        (!filters.selectedSeason ||
          booking.season === filters.selectedSeason) &&
        (!filters.selectedLocation ||
          booking.location?.includes(filters.selectedLocation)) &&
        (!filters.selectedMeetingType ||
          booking.meetingType === filters.selectedMeetingType) &&
        (!filters.selectedSyncedWithHubspot ||
          booking.syncedWithHubspot ===
            (filters.selectedSyncedWithHubspot === "Yes")) &&
        (!filters.fromDate ||
          moment(filters.fromDate).format("YYYY/MM/DD") <=
            moment(booking.date).format("YYYY/MM/DD")) &&
        (!filters.toDate ||
          moment(filters.toDate).format("YYYY/MM/DD") >=
            moment(booking.date).format("YYYY/MM/DD"))
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      ...filters,
      allBookingsRows: filteredUpdatedBookings,
    }));

    // Remove changed values (row) from the temp array
    setChangedValues(changedValues.filter((value) => value.id !== row.id));
  };

  const columns = [
    {
      field: "clientEmail",
      headerName: "Client",
      headerClassName: "first-header-style header-style",
      //   cellClassName: (param) => applyArchivedStyle(param.row.archived),
      // flex: 1,
      width: 325,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "company",
      headerName: "Company",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const company = cell.row.company;

        return (
          !!company && (
            <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  padding: 5,
                  gap: 5,
                }}
              >
                <CustomButton
                  key={`skipped-bookings-company-button`}
                  onClick={() =>
                    router.push(
                      "/dashboard/client-management/companies/" +
                        company.id +
                        "/details"
                    )
                  }
                  label={
                    <Stack
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CustomTooltip
                        title={
                          company.eTafStatus !== "Waiting for eTaf"
                            ? `eTaf ${
                                company.eTafStatus?.toLowerCase() || "No Status"
                              }`
                            : `${company.eTafStatus || "No Status"}`
                        }
                        key={"linesheet-status-tooltip"}
                      >
                        {statusCellComponent(company.eTafStatus)}
                      </CustomTooltip>
                      <Stack
                        style={{
                          textAlign: "left",
                          width: "100%",
                          padding: "0 10px",
                        }}
                      >
                        {company.name}
                      </Stack>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
          )
        );
      },
      // flex: 1.65,
      width: 300,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "brand_name",
      headerName: "Brand",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const brand = cell.row.brand_name || "";

        return (
          <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: 5,
                gap: 5,
              }}
            >
              <CustomButton
                key="skipped-bookings-brand"
                label={brand}
                style={{
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#E6E6E6",
                  },
                }}
              />
            </Stack>
          </Stack>
        );
      },
      // flex: 1.4,
      width: 325,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const category = cell.row.category || "";

        return (
          <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: 5,
                gap: 5,
              }}
            >
              <CustomButton
                key="all-bookings-category"
                label={category}
                style={{
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#E6E6E6",
                  },
                }}
              />
            </Stack>
          </Stack>
        );
      },
      // flex: 1,
      width: 250,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "season",
      headerName: "Season",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          row.value && (
            <Stack style={{ width: "100%", alignItems: "center" }}>
              <CustomButton
                label={row.value}
                style={{
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#E6E6E6",
                  },
                }}
              />
            </Stack>
          )
        );
      },
      // flex: 0.75,
      width: 200,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <Stack
            onClick={() => setNotClickedRow(null)}
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <CustomButton
              label={row.value}
              style={{
                cursor: "default",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "#E6E6E6",
                },
              }}
            />
          </Stack>
        );
      },
      // flex: 0.75,
      width: 275,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "header-style last-header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            {moment(row.value).format("DD/MM/YYYY")}
          </Stack>
        );
      },
      // flex: 0.75,
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "header-style last-header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      getActions: ({ id, row }) => {
        return [
          <CustomTooltip title="Delete" key={"delete-row-tooltip-" + id}>
            <ButtonBase
              onClick={() => setDeleteAppointmentModalId(row.appointmentId)}
            >
              <Delete />
            </ButtonBase>
          </CustomTooltip>,
        ];
      },
      // flex: 0.5,
      width: 125,
    },
  ];

  return columns;
};
